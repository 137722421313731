a {
    text-decoration: none;
    color: var(--main-color);
}

.header {
    width: 100%;
    height: 120px;
    align-items: center;
    justify-content: center;
    display: flex;
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    z-index: 3000;
    transition: all 1s ease-in-out;
}

.header .main {
    width: 90%;
    height: 100%;
    align-items: center;
    justify-content: space-between;
    display: flex;
}

.header .main.arb {
    flex-direction: row-reverse;
}

.header .main .logo {
    width: 200px;
    height: 100px;
    align-items: center;
    justify-content: start;
    display: flex;
}

.header .main.arb .logo {
    width: 300px;
    height: 100px;
    align-items: center;
    justify-content: end;
    display: flex;
}




.header .main .logo img {
    height: 90%;
}

.header .main.arb .logo img {
    height: 90%;
}





.header .main .nav {
    width: 60%;
    align-items: center;
    justify-content: end;
    display: flex;
    gap: 20px
}

.header .main .nav .menu-icon {
    display: none;
}

.header .main.arb .nav {
    justify-content: start;
    flex-direction: row-reverse;
}

.header .main .nav ul {
    align-items: center;
    justify-content: end;
    display: flex;
    gap: 20px;
    margin: 0;
    padding: 0;
    margin-left: 40px;
}

.activ {
    display: block;
}


.header .main.arb .nav ul {
    justify-content: start;
    flex-direction: row-reverse;
    margin-right: 40px;
}

.header .main .nav ul li {
    margin: 0;
    padding: 0;
    font-size: 20px;
    font-weight: 400;
    font-family: "Poppins", sans-serif;
    text-align: left;
    cursor: pointer;
    color: var(--main-color);

}

.header .main .nav ul li.arb {
    font-size: 20px;
    font-weight: 400;
    font-family: "Noto Kufi Arabic", sans-serif;
    text-align: right;
}

.header .main .nav ul li:hover {
    color: var(--secound-color);

}



/* Buttons */
/* Buttons */
/* Buttons */


.header .main .buttons {
    align-items: center;
    justify-content: center;
    display: flex;
    padding: 5px 25px;
    gap: 20px;


}

.header .main.arb .buttons {
    flex-direction: row-reverse;


}

.header .main .buttons button {
    width: 60px;
    height: 30px;
    background-color: transparent;
    padding: 5px 15px;
    font-size: large;
    border-radius: 5px;
    border: 2px solid var(--main-color);

    color: rgb(0, 0, 0);
    font-size: 18px;
    margin: 0;
    padding: 0;

}

.header .main .buttons button.act {
    width: 60px;
    padding: 5px 15px;
    font-size: large;
    border-radius: 5px;
    border: 2px solid var(--secound-color);
    background-color: var(--secound-color);
    color: rgb(255, 255, 255);
    font-size: 18px;
    margin: 0;
    padding: 0;
}

.header .main .buttons button:hover {
    border: 2px solid var(--secound-color);
    background-color: var(--secound-color);
    color: white
}


/* Scrolled */
/* Scrolled */
/* Scrolled */


.header.scrolled {
    height: 80px;
    border-bottom: 2px solid var(--main-color);
    box-shadow: 2px 2px 3px var(--main-color);
    background-color: rgba(255, 255, 255, 0.8);
    box-shadow: 4px 4px 6px #0d275045,
        -3px -3px 5px #FFFFFF,
        inset -2px -2px 3px #FFFFFF,
        inset 3px 3px 6px #0d275045;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    z-index: 3000;
}

.header.scrolled .main .logo {
    width: 200px;
    height: 80px;
    align-items: center;
    justify-content: center;
    display: flex;
}

.header.scrolled .main .logo img {
    height: 60px;
}




/* 1240 -- 1024 */
/* 1240 -- 1024 */
/* 1240 -- 1024 */



@media (max-width: 1240px) and (min-width: 1024px) {
    .header {
        width: 100%;
        height: 100px;
    }

    .header .main {
        width: 90%;
        height: 100%;
    }

    .header .main.arb {
        flex-direction: row-reverse;
    }

    .header .main .logo {
        width: 200px;
        height: 80px;
    }

    .header .main .logo img {
        height: 100%;
    }


    .header .main .nav {
        width: 65%;
        gap: 10px
    }


    .header .main .nav ul {
        align-items: center;
        justify-content: end;
        display: flex;
        gap: 15px;
        margin: 0;
        padding: 0;
        margin-left: 40px;
    }



    .header .main.arb .nav ul {
        justify-content: start;
        flex-direction: row-reverse;
        margin-right: 40px;
    }

    .header .main .nav ul li {
        font-size: 18px;
        font-weight: 400;

    }

    .header .main .nav ul li.arb {
        font-size: 18px;
    }

    .header .main .nav ul li:hover {
        color: var(--secound-color);

    }



    .header .main.arb .buttons {
        flex-direction: row-reverse;


    }

    .header .main .buttons button {
        width: 60px;
        height: 30px;
        background-color: transparent;
        padding: 5px 15px;
        font-size: large;
        border-radius: 5px;
        border: 2px solid var(--secound-color);
        background-color: var(--secound-color);
        color: rgb(255, 255, 255);
        font-size: 18px;
        margin: 0;
        padding: 0;

    }

    .header .main .buttons button.act {
        width: 60px;
        background-color: transparent;
        padding: 5px 15px;
        font-size: large;
        border-radius: 5px;
        border: 2px solid var(--main-color);
        color: black;
        font-size: 18px;
        margin: 0;
        padding: 0;
    }

    .header .main .buttons button:hover {
        border: 2px solid var(--secound-color);
        background-color: var(--secound-color);
        color: white
    }



    /* Scrolled */
    /* Scrolled */
    /* Scrolled */


    .header.scrolled {
        height: 80px;
        border-bottom: 2px solid var(--main-color);
        box-shadow: 2px 2px 3px var(--main-color);
        background-color: rgba(255, 255, 255, 0.577);
        box-shadow: 4px 4px 6px #0d275045,
            -3px -3px 5px #FFFFFF,
            inset -2px -2px 3px #FFFFFF,
            inset 3px 3px 6px #0d275045;
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
        z-index: 3000;
    }

    .header.scrolled .main .logo {
        width: 200px;
        height: 80px;
        align-items: center;
        justify-content: center;
        display: flex;
    }

    .header.scrolled .main .logo img {
        height: 60px;
    }



}


/* 900 -- 1024 */
/* 900 -- 1024 */
/* 900 -- 1024 */



@media (max-width: 1024px) and (min-width: 900px) {
    .header {
        width: 100%;
        height: 100px;
    }

    .header .main {
        width: 90%;
        height: 100%;
    }

    .header .main.arb {
        flex-direction: row-reverse;
    }

    .header .main .logo {
        width: 200px;
        height: 80px;
    }
    .header .main.arb .logo {
        width: 200px;
        height: 80px;
    }

    .header .main .logo img {
        height: 100%;
    }
    .header .main.arb .logo img {
        height: 80%;
    }


    .header .main .nav {
        width: 65%;
        gap: 10px
    }


    .header .main .nav ul {
        align-items: center;
        justify-content: end;
        display: flex;
        gap: 15px;
        margin: 0;
        padding: 0;
        margin-left: 20px;
    }



    .header .main.arb .nav ul {
        justify-content: start;
        flex-direction: row-reverse;
        margin-right: 40px;
    }

    .header .main .nav ul li {
        font-size: 18px;
        font-weight: 400;

    }

    .header .main .nav ul li.arb {
        font-size: 18px;
    }

    .header .main .nav ul li:hover {
        color: var(--secound-color);

    }



    /* Buttons */
    /* Buttons */
    /* Buttons */


    .header .main .buttons {
        align-items: center;
        justify-content: center;
        display: flex;
        padding: 5px 25px;
        gap: 15px;


    }

    .header .main.arb .buttons {
        flex-direction: row-reverse;


    }

    .header .main .buttons button {
        width: 55px;
        padding: 3px 12px;
        font-size: large;
        border-radius: 5px;
        font-size: 16px;
    }

    .header .main .buttons button.active {
        width: 55px;
        padding: 3px 12px;
        font-size: large;
        border-radius: 5px;
    }


    /* Scrolled */
    /* Scrolled */
    /* Scrolled */


    .header.scrolled {
        height: 80px;
    }

    .header.scrolled .main .logo {
        width: 200px;
        height: 80px;
        align-items: center;
        justify-content: center;
        display: flex;
    }

    .header.scrolled .main .logo img {
        height: 60px;
    }



}



/* 768 -- 900 */
/* 768 -- 900 */
/* 768 -- 900 */



@media (max-width: 900px) and (min-width: 768px) {
    .header {
        width: 100%;
        height: 80px;
    }

    .header .main {
        width: 90%;
        height: 100%;
    }

    .header .main.arb {
        flex-direction: row-reverse;
    }

    .header .main .logo {
        width: 200px;
        height: 70px;
    }
    .header .main.arb .logo {
        width: 200px;
        height: 70px;
    }

    .header .main .logo img {
        height: 100%;
    }


    .header .main.arb .logo img {
        height: 100%;
    }




    .header .main .nav {
        width: 65%;
        gap: 10px
    }

    .header .main .nav .menu-icon {
        display: block;
    }

    .header .main .nav .menu-icon .icon {
        font-size: 25px;

    }


    .header .main .nav nav {
        display: none;
    }

    .header .main .nav nav.activ {
        display: block;
    }

    .header .main .nav nav ul {
        top: 90px;
        right: 10px;
        width: 50%;
        height: 300px;
        position: absolute;
        align-items: center;
        justify-content: start;
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin: 0;
        padding: 0;
        border-bottom: 2px solid var(--main-color);
        box-shadow: 2px 2px 3px var(--main-color);
        background-color: rgba(255, 255, 255, 0.829);
        box-shadow: 4px 4px 6px #0d275045,
            -3px -3px 5px #FFFFFF,
            inset -2px -2px 3px #FFFFFF,
            inset 3px 3px 6px #0d275045;
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
        padding-top: 50px;
    }


    .header .main.arb .nav nav ul {
        top: 90px;
        left: 10px;
        width: 50%;
        height: 300px;
        position: absolute;
        align-items: center;
        justify-content: start;
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin: 0;
        padding: 0;
        border-bottom: 2px solid var(--main-color);
        box-shadow: 2px 2px 3px var(--main-color);
        background-color: rgba(255, 255, 255, 0.829);
        box-shadow: 4px 4px 6px #0d275045,
            -3px -3px 5px #FFFFFF,
            inset -2px -2px 3px #FFFFFF,
            inset 3px 3px 6px #0d275045;
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
        padding-top: 50px;
    }




    .header .main .nav nav ul li {
        margin: 0;
        padding: 0;
        font-size: 20px;
        font-weight: 400;
        font-family: "Poppins", sans-serif;
        text-align: left;
        cursor: pointer;
        color: var(--main-color);

    }

    .header .main .nav ul li.arb {
        font-size: 20px;
        font-weight: 400;
        font-family: "Noto Kufi Arabic", sans-serif;
        text-align: right;
    }

    .header .main .nav ul li:hover {
        color: var(--secound-color);

    }


    /* Buttons */
    /* Buttons */
    /* Buttons */


    .header .main .buttons {
        align-items: center;
        justify-content: center;
        display: flex;
        padding: 5px 25px;
        gap: 20px;


    }

    .header .main.arb .buttons {
        flex-direction: row-reverse;


    }

    .header .main .buttons button {
        width: 55px;
        padding: 3px 12px;
        font-size: large;
        border-radius: 5px;
    }

    .header .main .buttons button.active {
        width: 55px;
        padding: 3px 12px;
        font-size: large;
        border-radius: 5px;
    }

    /* Scrolled */
    /* Scrolled */
    /* Scrolled */


    .header.scrolled {
        height: 80px;
        border-bottom: 2px solid var(--main-color);
        box-shadow: 2px 2px 3px var(--main-color);
        background-color: rgba(255, 255, 255, 0.577);
        box-shadow: 4px 4px 6px #0d275045,
            -3px -3px 5px #FFFFFF,
            inset -2px -2px 3px #FFFFFF,
            inset 3px 3px 6px #0d275045;
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
        z-index: 3000;
    }

    .header.scrolled .main .logo {
        width: 200px;
        height: 80px;
        align-items: center;
        justify-content: center;
        display: flex;
    }

    .header.scrolled .main .logo img {
        height: 60px;
    }



}


/* 480 -- 767 */
/* 480 -- 767 */
/* 480 -- 767 */



@media (max-width: 767px) and (min-width: 480px) {
    .header {
        width: 100%;
        height: 80px;
    }

    .header .main {
        width: 90%;
        height: 100%;
    }

    .header .main.arb {
        flex-direction: row-reverse;
    }

    .header .main .logo {
        width: 130px;
        height: 70px;
        align-items: center;
        justify-content: start;
        display: flex;
    }

    .header .main.arb .logo {
        width: 130px;
        height: 70px;
        align-items: center;
        justify-content: end;
        display: flex;
    }

    .header .main .logo img {
        height: 100%;
    }

    .header .main.arb .logo img {
        height: 100%;
    }

    .header .main .nav {
        width: 65%;
        gap: 10px
    }

    .header .main .nav .menu-icon {
        display: block;
    }

    .header .main .nav .menu-icon .icon {
        font-size: 25px;

    }

    .header .main .nav nav {
        display: none;
    }

    .header .main .nav nav.activ {
        display: block;
    }


    .header .main .nav nav ul {
        top: 90px;
        right: 10px;
        width: 70%;
        height: 300px;
        position: absolute;
        align-items: center;
        justify-content: start;
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin: 0;
        padding: 0;
        border-bottom: 2px solid var(--main-color);
        box-shadow: 2px 2px 3px var(--main-color);
        background-color: rgba(255, 255, 255, 0.829);
        box-shadow: 4px 4px 6px #0d275045,
            -3px -3px 5px #FFFFFF,
            inset -2px -2px 3px #FFFFFF,
            inset 3px 3px 6px #0d275045;
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
        padding-top: 80px;
    }

    .header .main.arb .nav nav ul {
        top: 90px;
        left: 10px;
        width: 50%;
        height: 300px;
        position: absolute;
        align-items: center;
        justify-content: start;
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin: 0;
        padding: 0;
        border-bottom: 2px solid var(--main-color);
        box-shadow: 2px 2px 3px var(--main-color);
        background-color: rgba(255, 255, 255, 0.829);
        box-shadow: 4px 4px 6px #0d275045,
            -3px -3px 5px #FFFFFF,
            inset -2px -2px 3px #FFFFFF,
            inset 3px 3px 6px #0d275045;
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
        padding-top: 50px;
    }




    .header .main .nav nav ul li {
        margin: 0;
        padding: 0;
        font-size: 20px;
        font-weight: 400;
        font-family: "Poppins", sans-serif;
        text-align: left;
        cursor: pointer;
        color: var(--main-color);

    }

    .header .main .nav ul li.arb {
        font-size: 20px;
        font-weight: 400;
        font-family: "Noto Kufi Arabic", sans-serif;
        text-align: right;
    }

    .header .main .nav ul li:hover {
        color: var(--secound-color);

    }


    /* Buttons */
    /* Buttons */
    /* Buttons */


    .header .main .buttons {
        align-items: center;
        justify-content: center;
        display: flex;
        padding: 5px 15px;
        gap: 10px;


    }



    .header .main .buttons button {
        width: 60px;
        height: 30px;
        background-color: transparent;
        padding: 5px 15px;
        border-radius: 5px;
        border: 2px solid var(--main-color);
        font-size: 18px;
        margin: 0;
        padding: 0;

    }

    .header .main .buttons button.act {
        width: 60px;
        padding: 5px 15px;
        border-radius: 5px;
        border: 2px solid var(--secound-color);
        font-size: 18px;
        margin: 0;
        padding: 0;
    }

    .header .main .buttons button:hover {
        border: 2px solid var(--secound-color);
        background-color: var(--secound-color);
        color: white
    }

    /* Scrolled */
    /* Scrolled */
    /* Scrolled */


    .header.scrolled {
        height: 80px;
        border-bottom: 2px solid var(--main-color);
        box-shadow: 2px 2px 3px var(--main-color);
        background-color: rgba(255, 255, 255, 0.577);
        box-shadow: 4px 4px 6px #0d275045,
            -3px -3px 5px #FFFFFF,
            inset -2px -2px 3px #FFFFFF,
            inset 3px 3px 6px #0d275045;
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
        z-index: 3000;
    }

    .header.scrolled .main {
        width: 90%;
        height: 100%;
        align-items: center;
        justify-content: space-between;
        display: flex;
    }





    .header.scrolled .main .logo {
        width: 130px;
        height: 70px;
        align-items: center;
        justify-content: start;
        display: flex;
    }

    .header.scrolled .main.arb .logo {
        width: 130px;
        height: 70px;
        align-items: center;
        justify-content: end;
        display: flex;
    }

    .header.scrolled .main .logo img {
        height: 100%;
    }

    .header.scrolled .main.arb .logo img {
        height: 100%;
    }


}


/* 400 -- 479 */
/* 400 -- 479 */
/* 400 -- 479 */







@media (max-width: 479px) and (min-width: 275px) {
    .header {
        width: 100%;
        height: 60px;
    }

    .header .main {
        width: 90%;
        height: 100%;
    }

    .header .main.arb {
        flex-direction: row-reverse;
    }

    .header .main .logo {
        width: 130px;
        height: 50px;
        align-items: center;
        justify-content: start;
        display: flex;
    }

    .header .main.arb .logo {
        width: 130px;
        height: 50px;
        align-items: center;
        justify-content: end;
        display: flex;
    }

    .header .main .logo img {
        height: 100%;
    }

    .header .main.arb .logo img {
        height: 100%;
    }

    .header .main .nav {
        width: 65%;
        gap: 10px
    }

    .header .main .nav .menu-icon {
        display: block;
    }

    .header .main .nav .menu-icon .icon {
        font-size: 25px;

    }

    .header .main .nav nav {
        display: none;
    }

    .header .main .nav nav.activ {
        display: block;
    }


    .header .main .nav nav ul {
        top: 90px;
        right: 10px;
        width: 70%;
        height: 300px;
        position: absolute;
        align-items: center;
        justify-content: start;
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin: 0;
        padding: 0;
        border-bottom: 2px solid var(--main-color);
        box-shadow: 2px 2px 3px var(--main-color);
        background-color: rgba(255, 255, 255, 0.829);
        box-shadow: 4px 4px 6px #0d275045,
            -3px -3px 5px #FFFFFF,
            inset -2px -2px 3px #FFFFFF,
            inset 3px 3px 6px #0d275045;
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
        padding-top: 80px;
    }

    .header .main.arb .nav nav ul {
        top: 90px;
        left: 10px;
        width: 70%;
        height: 300px;
        position: absolute;
        align-items: center;
        justify-content: start;
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin: 0;
        padding: 0;
        border-bottom: 2px solid var(--main-color);
        box-shadow: 2px 2px 3px var(--main-color);
        background-color: rgba(255, 255, 255, 0.829);
        box-shadow: 4px 4px 6px #0d275045,
            -3px -3px 5px #FFFFFF,
            inset -2px -2px 3px #FFFFFF,
            inset 3px 3px 6px #0d275045;
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
        padding-top: 50px;
    }




    .header .main .nav nav ul li {
        margin: 0;
        padding: 0;
        font-size: 20px;
        font-weight: 400;
        font-family: "Poppins", sans-serif;
        text-align: left;
        cursor: pointer;
        color: var(--main-color);

    }

    .header .main .nav ul li.arb {
        font-size: 20px;
        font-weight: 400;
        font-family: "Noto Kufi Arabic", sans-serif;
        text-align: right;
    }

    .header .main .nav ul li:hover {
        color: var(--secound-color);

    }


    /* Buttons */
    /* Buttons */
    /* Buttons */


    .header .main .buttons {
        align-items: center;
        justify-content: center;
        display: flex;
        padding: 5px 15px;
        gap: 10px;


    }



    .header .main .buttons button {
        width: 60px;
        height: 30px;
        background-color: transparent;
        padding: 5px 15px;
        border-radius: 5px;
        border: 2px solid var(--main-color);
        font-size: 16px;
        margin: 0;
        padding: 0;

    }

    .header .main .buttons button.act {
        width: 60px;
        padding: 5px 15px;
        border-radius: 5px;
        border: 2px solid var(--secound-color);
        font-size: 18px;
        margin: 0;
        padding: 0;
    }

    .header .main .buttons button:hover {
        border: 2px solid var(--secound-color);
        background-color: var(--secound-color);
        color: white
    }

    /* Scrolled */
    /* Scrolled */
    /* Scrolled */


    .header.scrolled {
        height: 60px;
        border-bottom: 2px solid var(--main-color);
        box-shadow: 2px 2px 3px var(--main-color);
        background-color: rgba(255, 255, 255, 0.577);
        box-shadow: 4px 4px 6px #0d275045,
            -3px -3px 5px #FFFFFF,
            inset -2px -2px 3px #FFFFFF,
            inset 3px 3px 6px #0d275045;
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
        z-index: 3000;
    }

    .header.scrolled .main {
        width: 90%;
        height: 100%;
        align-items: center;
        justify-content: space-between;
        display: flex;
    }





    .header.scrolled .main .logo {
        width: 130px;
        height: 50px;
        align-items: center;
        justify-content: start;
        display: flex;
    }

    .header.scrolled .main.arb .logo {
        width: 130px;
        height: 50px;
        align-items: center;
        justify-content: end;
        display: flex;
    }

    .header.scrolled .main .logo img {
        height: 100%;
    }

    .header.scrolled .main.arb .logo img {
        height: 100%;
    }



}