.call {
    background: url("../bg.jpg");
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    height: 100vh;
}

.call .inner {
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    position: relative;
}

.call .inner .main {
    width: 80%;
    max-width: 1000px;
    border-radius: 15px;
    background-color: #ffffff64;
    box-shadow: 4px 4px 6px #0d275045,
        -3px -3px 5px white;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    position: relative;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    z-index: 101;
    padding-top: 100px;
    padding-bottom: 50px;
    margin-bottom: 100px;
}


.call .inner .main h2 {

    width: 80%;
    color: black;
    margin: 0;
    padding: 0;
    text-align: center;
    -webkit-text-stroke-width: 0px;
    -webkit-text-stroke-color: black;
    text-shadow: none;
    letter-spacing: 0;
    font-size: 32px;
    font-family: "Oswald", sans-serif;
    font-weight: 500;
}

.call .inner .main h2.arb {
    font-size: 30px;
    font-family: "Noto Kufi Arabic", sans-serif;
    font-weight: 600;
}


.call .inner .box {
    position: absolute;
    width: 100%;
    height: 100px;
    background-color: var(--main-color);
    bottom: 0;
}

.call .inner .box {
    position: absolute;
    width: 100%;
    height: 100px;
    background-color: var(--main-color);
    bottom: 0;
    z-index: 100;
}



.call .inner .box img {
    position: absolute;
    height: 500px;
    bottom: 10px;
    left: 50px;
}

/* 1024 -- 1240 */
/* 1024 -- 1240 */
/* 1024 -- 1240 */

@media (min-width: 1024px) and (max-width: 1240px) {

    .call {
        height: 100vh;
        max-height: 800px;
    }

    .call .inner {
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
        display: flex;
        position: relative;
    }

    .call .inner .main {
        width: 80%;
        border-radius: 10px;
        padding-top: 80px;
        padding-bottom: 80px;
        margin-bottom: 50px;
    }


    .call .inner .main h2 {

        width: 80%;
    }

    .call .inner .main h2 {

        width: 80%;
        color: black;
        font-size: 26px;
        font-weight: 500;
    }

    .call .inner .main h2.arb {
        font-size: 24px;
        font-weight: 600;
    }




    .call .inner .box {
        position: absolute;
        width: 100%;
        height: 50px;
        bottom: 0;
        left: 0px;
        z-index: 100;
    }



    .call .inner .box img {
        position: absolute;
        height: 350px;
        bottom: 0px;
        left: 00px;
    }

}
/* 768 -- 1023 */
/* 768 -- 1023 */
/* 768 -- 1023 */

@media (min-width: 768px) and (max-width: 1023px) {

    .call {
        height: 100vh;
        max-height: 750px
        ;
        min-height: 650px;
    }

    .call .inner .main {
        width: 80%;
        border-radius: 10px;
        padding-top: 50px;
        padding-bottom: 50px;
        margin-bottom: 50px;
    }


    .call .inner .main h2 {

        width: 80%;
    }

    .call .inner .main h2 {

        width: 80%;
        color: black;
        font-size: 26px;
        font-weight: 500;
    }

    .call .inner .main h2.arb {
        font-size: 24px;
        font-weight: 600;
    }




    .call .inner .box {
        position: absolute;
        width: 100%;
        height: 50px;
        bottom: 0;
        left: 0px;
        z-index: 100;
    }



    .call .inner .box img {
        position: absolute;
        height: 350px;
        bottom: 0px;
        left: 00px;
    }

}



/* 480 -- 767 */
/* 480 -- 767 */
/* 480 -- 767 */

@media (min-width: 480px) and (max-width: 767px) {

    .call {
        height: 100vh;
        max-height: 600px;
    }

    .call .inner .main {
        width: 90%;
        max-width: 550px;
        border-radius: 10px;
        padding-top: 50px;
        padding-bottom: 50px;
        margin-bottom: 50px;
    }


    .call .inner .main h2 {

        width: 90%;
    }

    .call .inner .main h2 {

        width: 95%;
        color: black;
        font-size: 20px;
        font-weight: 500;
    }

    .call .inner .main h2.arb {
        font-size: 22px;
        font-weight: 600;
    }




    .call .inner .box {
        position: absolute;
        width: 100%;
        height: 50px;
        bottom: 0;
        left: 0px;
        z-index: 100;
    }



    .call .inner .box img {
        position: absolute;
        height: 280px;
        bottom: 0px;
        left: 00px;
    }

}


/* 275 -- 479 */
/* 275 -- 479 */
/* 275 -- 479 */

@media (min-width: 275px) and (max-width: 479px) {

    .call {
        height: 100vh;
        max-height: 700px;
        background-attachment: scroll;
       
    }

    .call .inner .main {
        width: 90%;
        max-width: 550px;
        border-radius: 10px;
        padding-top: 50px;
        padding-bottom: 50px;
        margin-bottom: 50px;
    }


    .call .inner .main h2 {

        width: 90%;
    }

    .call .inner .main h2 {

        width: 95%;
        color: black;
        font-size: 20px;
        font-weight: 500;
    }

    .call .inner .main h2.arb {
        font-size: 22px;
        font-weight: 600;
    }




    .call .inner .box {
        position: absolute;
        width: 100%;
        height: 50px;
        bottom: 0;
        left: 0px;
        z-index: 100;
    }



    .call .inner .box img {
        position: absolute;
        height: 280px;
        bottom: 0px;
        left: 00px;
    }

}