.page-header {
    width: 100%;
    height: 100vh;
    margin-top: -120px;
    background-image: url("../images/page/02.jpg");
    background-size: cover;
    background-position: top;
    position: relative;

}

.page-header .heading {
    position: absolute;
    width: 100%;
    padding: 20px 0;
    align-items: center;
    justify-content: center;
    display: flex;
    bottom: 20px;
    border-bottom: 2px solid var(--main-color);
    box-shadow: 2px 2px 3px var(--main-color);
    background-color: rgba(255, 255, 255, 0.8);
    box-shadow: 4px 4px 6px #0d275045,
        -3px -3px 5px #FFFFFF,
        inset -2px -2px 3px #FFFFFF,
        inset 3px 3px 6px #0d275045;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);

}

.page-header .heading h1 {
    text-align: center;
}

.page-border {
    width: 100%;
    height: 40px;
    background-color: var(--main-color);
}

.page-border.green {
    background-color: var(--secound-color);
}


/* 768 -- 1023 */
/* 768 -- 1023 */
/* 768 -- 1023 */

@media (min-width: 768px) and (max-width: 1023px) {
    .page-header {
        width: 100%;
        height: 100vh;
        min-height: 700px;
        max-height: 800px;
        margin-top: -80px;
        background-image: url("../images/page/02-tab.jpg");
        background-size: cover;
        background-position: center;
        position: relative;

    }

    .page-header .heading {
        position: absolute;
        width: 100%;
        padding: 20px 0;
        align-items: center;
        justify-content: center;
        display: flex;
        bottom: 50px;
        border-bottom: 2px solid var(--main-color);
        box-shadow: 2px 2px 3px var(--main-color);
        background-color: rgba(255, 255, 255, 0.8);
        box-shadow: 4px 4px 6px #0d275045,
            -3px -3px 5px #FFFFFF,
            inset -2px -2px 3px #FFFFFF,
            inset 3px 3px 6px #0d275045;
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);

    }

    .page-header .heading h1 {
        text-align: center;
    }

    .page-border {
        width: 100%;
        height: 40px;
        background-color: var(--main-color);
    }

    .page-border.green {
        background-color: var(--secound-color);
    }



}


/* 768 -- 1023 */
/* 768 -- 1023 */
/* 768 -- 1023 */

@media (min-width: 260px) and (max-width: 767px) {
    .page-header {
        width: 100%;
        height: 100vh;
        max-height: 800px;
        margin-top: -60px;
        background-image: url("../images/page/02-mob.jpg");
        background-size: cover;
        background-position: center;
        position: relative;

    }

    .page-header .heading {
        position: absolute;
        width: 100%;
        padding: 20px 0;
        align-items: center;
        justify-content: center;
        display: flex;
        bottom: 80px;
        border-bottom: 2px solid var(--main-color);
        box-shadow: 2px 2px 3px var(--main-color);
        background-color: rgba(255, 255, 255, 0.8);
        box-shadow: 4px 4px 6px #0d275045,
            -3px -3px 5px #FFFFFF,
            inset -2px -2px 3px #FFFFFF,
            inset 3px 3px 6px #0d275045;
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);

    }

    .page-header .heading h1 {
        text-align: center;
    }

    .page-border {
        width: 100%;
        height: 30px;
        background-color: var(--main-color);
    }

    .page-border.green {
        background-color: var(--secound-color);
    }



}