.service {
    width: 100%;
    height: 100%;
    padding: 60px 0;
    align-items: center;
    justify-content: center;
    display: flex;
    z-index: 500;

}
.service .main{
    width: 80%;
    max-width: 1340px;
    height: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
}
.service .list {
    width: 80%;
    max-width: 1340px;
    height: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    gap: 20px;
    z-index: 501;
}

.service .main  h1 {
    width: 100%;
    text-align: center;
    margin: 0;
    padding: 0;
}

.service .main h1.arb {
    margin-bottom: 0px;
    text-align: center;
}

.service .main .border {
    width: 30%;
    margin-bottom: 20px;
}


.service .list .item {
    width: 90%;
    aspect-ratio: 5/2;
    z-index: 502;
    flex-grow: 1;
    box-sizing: border-box;
    border-radius: 15px;
    background-color: rgba(255, 255, 255, 0.577);
    box-shadow: 4px 4px 6px #0d275045,
        -3px -3px 5px #FFFFFF,
        inset -2px -2px 3px #FFFFFF,
        inset 3px 3px 6px #0d275045;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    overflow: hidden;
    position: relative;
    align-items: center;
    justify-content: start;
    display: flex;
    padding-left: 40px;
}

.service .list .item.right {
    justify-content: end;
    padding-left: 00px;
    padding-right: 40px;
}


/* Images */
/* Images */
/* Images */



.service .list .item .img {
    position: absolute;
    width: 30%;

}

.service .list .item.pack .img {
    bottom: 0;
    right: 0;
}

.service .list .item.flower .img {
    top: 0;
    left: 0;
}

.service .list .item.food .img {
    bottom: 0;
    right: 0;
}

.service .list .item.pet .img {
    bottom: -15px;
    left: 0px;
}

.service .list .item .img img {
    width: 100%;
}


/* Content */
/* Content */
/* Content */

.service .list .item .content {
    width: 65%;
    height: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
}

.service .list .item .content h2 {
    width: 90%;
    text-align: left;
    color: var(--main-color);
    -webkit-text-stroke-width: 0px;
    text-shadow: none;
}

.service .list .item .content h2.arb {

    text-align: right;
}

.service .list .item .content p {
    width: 90%;
    margin-top: 10px;
}


/* 1024 -- 1240 */
/* 1024 -- 1240 */
/* 1024 -- 1240 */

@media (min-width: 1024px) and (max-width: 1240px) {
    .service {
        width: 100%;
        height: 100%;
        padding: 60px 0;
        align-items: center;
        justify-content: center;
        display: flex;
        z-index: 500;

    }
    .service .main{
        width: 90%;
    }


    .service .list {
        width: 100%;
        min-width: 1000px;
        gap: 25px;
    }

    .service .list h1 {
        text-align: center;
    }

    .service .list h1.arb {
        margin-bottom: 0px;
        text-align: center;
    }

    .service .list .border {
        width: 35%;
    }


    .service .list .item {
        width: 85%;
        aspect-ratio: 6/2;
    }

    .service .list .item.right {
        padding-right: 40px;
    }


}


/* 768 -- 1023 */
/* 768 -- 1023 */
/* 768 -- 1023 */

@media (min-width: 768px) and (max-width: 1023px) {
    .service {
        width: 100%;
        height: 100%;
        padding: 50px 0;
        align-items: center;
        justify-content: center;
        display: flex;
        z-index: 500;

    }
    .service .main{
        width: 90%;
    }

    .service .list {
        width: 100%;
        gap: 25px;
    }

    .service .main h1 {
        text-align: center;
        margin: 5px;
    }

    .service .main h1.arb {
        margin-bottom: 5px;
        text-align: center;

    }

    .service .main .border {
        width: 35%;
        margin-bottom: 20px;
    }


    .service .list .item {
        width: 95%;
        aspect-ratio: 4/2;
    }

    .service .list .item.right {
        padding-right: 40px;
    }


}




/* 480 -- 767 */
/* 480 -- 767 */
/* 480 -- 767 */

@media (min-width: 480px) and (max-width: 767px) {

    .service .main{
        width: 90%;
    }
    
    .service .list {
        width: 100%;
        height: 100%;
        gap: 20px;
    }
    
    .service  h1 {
        text-align: center;
        margin: 0;
        padding: 0;
      
    }
 
    
    .service .main .border {
        width: 50%;
        margin-bottom: 20px;
    }
    
    
    .service .list .item {
        width: 100%;
        max-width: 500px;
        aspect-ratio: 5/7;
        max-height: 640px;
        position: relative;
        align-items: start;
        justify-content: center;
        display: flex;
        padding-left: 0px;
    }
    
    .service .list .item.right {
        justify-content: center;
        padding-left: 0px;
        padding-right: 0px;

    }
    

    .service .list .item.flower  {
        align-items: end;
    }
    
    /* Images */
    /* Images */
    /* Images */
    
    
    
    .service .list .item .img {
        position: absolute;
        width: 60%;
    
    }
    
    .service .list .item.pack .img {
        bottom: 0;
        right: 0;
    }
    
    .service .list .item.flower .img {
        top: 0;
        left: 0;
    }
    
    .service .list .item.food .img {
        bottom: 0;
        right: 0;
    }
    
    .service .list .item.pet .img {
        bottom: -15px;
        left: 0px;
    }
    
    .service .list .item .img img {
        width: 100%;
    }
    
    
    /* Content */
    /* Content */
    /* Content */
    
    .service .list .item .content {
        width: 90%;
        height: 55%;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
    }
    .service .list .item.pet .content {
       margin-top: 20px;
    }
    
    .service .list .item .content h2 {
        width: 90%;
        text-align: left;
        color: var(--main-color);
        -webkit-text-stroke-width: 0px;
        text-shadow: none;
    }
    
    .service .list .item .content h2.arb {
    
        text-align: right;
    }
    
    .service .list .item .content p {
        width: 90%;
        margin-top: 10px;
    }


}


/* 275 -- 479 */
/* 275 -- 479 */
/* 275 -- 479 */

@media (min-width: 275px) and (max-width: 479px) {

    .service .main{
        width: 90%;
    }
    
    .service .list {
        width: 100%;
        height: 100%;
        gap: 20px;
    }
    
    .service  h1 {
        text-align: center;
        margin: 0;
        padding: 0;
      
    }
 
    
    .service .main .border {
        width: 50%;
        margin-bottom: 20px;
    }
    
    
    .service .list .item {
        width: 100%;
        max-width: 500px;
        aspect-ratio: 5/7;
        max-height: 640px;
        position: relative;
        align-items: start;
        justify-content: center;
        display: flex;
        padding-left: 0px;
    }
    
    .service .list .item.right {
        justify-content: center;
        padding-left: 0px;
        padding-right: 0px;

    }
    

    .service .list .item.flower  {
        align-items: end;
    }
    
    /* Images */
    /* Images */
    /* Images */
    
    
    
    .service .list .item .img {
        position: absolute;
        width: 60%;
    
    }
    
    .service .list .item.pack .img {
        bottom: 0;
        right: 0;
    }
    
    .service .list .item.flower .img {
        top: 0;
        left: 0;
    }
    
    .service .list .item.food .img {
        bottom: 0;
        right: 0;
    }
    
    .service .list .item.pet .img {
        bottom: -15px;
        left: 0px;
    }
    
    .service .list .item .img img {
        width: 100%;
    }
    
    
    /* Content */
    /* Content */
    /* Content */
    
    .service .list .item .content {
        width: 90%;
        height: 55%;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
    }
    .service .list .item.pet .content {
       margin-top: 20px;
    }
    
    .service .list .item .content h2 {
        width: 90%;
        text-align: left;
        color: var(--main-color);
        -webkit-text-stroke-width: 0px;
        text-shadow: none;
    }
    
    .service .list .item .content h2.arb {
    
        text-align: right;
    }
    
    .service .list .item .content p {
        width: 90%;
        margin-top: 10px;
    }


}
