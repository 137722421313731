.about {
    width: 100%;
    height: 100%;
    background-image: url("../images/intro/01.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 150px 0;
    position: relative;
    align-items: center;
    justify-content: center;
    display: flex;
}

.about::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #ffffffc0;
    z-index: 10;
}

.about .content {
    width: 90%;
    max-width: 1350px;
    align-items: center;
    justify-content: end;
    display: flex;
    z-index: 11;
}

.about .content .main {
    width: 60%;
}

.about .content h1 {
    width: 100%;
    text-align: left;
}

.about .content h1.arb {
    text-align: right;
}

.about .content .border {
    width: 100%;
}

.about .content.dis {
    margin-top: 40px;
    align-items: center;
    justify-content: start;
    display: flex;

}

.about .content .dis p {
    margin-top: 30px;
    width: 100%;
    text-align: left;
}

.about .content .dis p.arb {
    width: 100%;
    text-align: right;
}

.about .border-bottom {
    position: absolute;
    z-index: 16;
    bottom: 0;
    width: 100%;
    background-color: var(--main-color);
    height: 25px;
}

.about .images {
    z-index: 15;
    position: absolute;
    bottom: 0;
    left: 50px;
    width: 400px;
}


/* 1024 -- 1240 */
/* 1024 -- 1240 */
/* 1024 -- 1240 */

@media (min-width: 1024px) and (max-width: 1240px) {
    .about {
        padding: 90px 0;
    }



    .about .content {
        width: 95%;
        align-items: center;
        justify-content: end;
        display: flex;
        z-index: 11;
    }

    .about .content .main {
        width: 60%;
    }




    .about .content.dis {
        margin-top: 30px;
    }

    .about .content .dis p {
        margin-top: 30px;
    }



    .about .border-bottom {
        position: absolute;
        z-index: 16;
        bottom: 0;
        width: 100%;
        background-color: var(--main-color);
        height: 25px;
    }

    .about .images {
        z-index: 15;
        position: absolute;
        bottom: 0;
        left: 50px;
        width: 370px;
        left: 0;
    }

    .about .images img {
        width: 100%;
    }
}

/* 768 -- 1023 */
/* 768 -- 1023 */
/* 768 -- 1023 */

@media (min-width: 768px) and (max-width: 1023px) {
    .about {
        padding: 70px 0;
    }



    .about .content {
        width: 95%;
        align-items: center;
        justify-content: end;
        display: flex;
        z-index: 11;
    }

    .about .content .main {
        width: 63%;
    }




    .about .content.dis {
        margin-top: 30px;
    }

    .about .content .dis p {
        margin-top: 30px;
    }



    .about .border-bottom {
        position: absolute;
        z-index: 16;
        bottom: 0;
        width: 100%;
        background-color: var(--main-color);
        height: 25px;
    }

    .about .images {
        z-index: 15;
        left: 50px;
        width: 350px;
        left: -50px;
    }

    .about .images img {
        width: 100%;
    }
}

/* 480 -- 767 */
/* 480 -- 767 */
/* 480 -- 767 */

@media (min-width: 480px) and (max-width: 767px) {
    .about {
        padding: 50px 0;
        padding-bottom: 55vw;

    }

    .about::after {
        bottom: 0;
    }

    .about .content {
        width: 90%;
        align-items: center;
        justify-content: end;
        display: flex;
        z-index: 11;
    }

    .about .content .main {
        width: 100%;
    }




    .about .content.dis {
        margin-top: 30px;
    }

    .about .content .dis p {
        margin-top: 30px;
    }



    .about .border-bottom {
        height: 20px;
    }

    .about .images {
        width: 50%;
        max-width: 400px;
        left: 50%;
        transform: translateX(-50%);
    }

    .about .images img {
        width: 100%;
    }
}


/* 275 -- 479 */
/* 275 -- 479 */
/* 275 -- 479 */

@media (min-width: 275px) and (max-width: 479px) {
    .about {
        padding: 40px 0;
        padding-bottom: 70vw;

    }

    .about::after {
        bottom: 0;
    }

    .about .content {
        width: 90%;
        align-items: center;
        justify-content: end;
        display: flex;
        z-index: 11;
    }

    .about .content .main {
        width: 100%;
    }




    .about .content.dis {
        margin-top: 30px;
    }

    .about .content .dis p {
        margin-top: 30px;
    }



    .about .border-bottom {
        height: 20px;
    }

    .about .images {
        width: 65%;
        max-width: 400px;
        left: 50%;
        transform: translateX(-50%);
    }

    .about .images img {
        width: 100%;
    }
}