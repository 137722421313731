.footer {
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    padding: 30px 0;
    overflow: hidden;
    padding-bottom: 50px;
}

.footer .main {
    width: 90%;
    max-width: 1340px;
    align-items: start;
    justify-content: space-between;
    display: flex;
}

.footer .main .logo {
    width: 30%;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
}

.footer .main .logo img {
    width: 80%;
}


/* Quick */
/* Quick */
/* Quick */


.footer .main .quick {
    width: 30%;
    align-items: center;
    justify-content: start;
    display: flex;
    flex-direction: column;

}

.footer .main .quick h2 {
    width: 100%;
}



.footer .main .quick .border {
    width: 80%;

}

.footer .main .quick ul {
    width: 90%;
}

.footer .main .quick ul li {
    margin: 0;
    margin: 5px 0;
    text-align: start;
}

.footer .main .quick ul li.arb {
    margin: 0;
    margin: 5px 0;
    text-align: end;
    margin-right: 45px;
}

.footer .main .quick ul>li>a {
    margin: 5px 0;
    font-size: 22px;
    color: black;
}


/* Contact */
/* Contact */
/* Contact */


.footer .main .contactus {
    width: 30%;
    align-items: end;
    justify-content: start;
    display: flex;
    flex-direction: column;

}

.footer .main .contactus h2 {
    width: 100%;
    text-align: end;

}

.footer .main .contactus .border {
    width: 80%;

}

.footer .main .contactus .contact-details {
    margin-top: 23px;
    width: 100%;
    align-items: end;
    justify-content: start;
    display: flex;
    flex-direction: column;

}

.footer .main .contactus .contact-details .item {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: row-reverse;
    margin: 2px 0;
}

.footer .main .contactus .contact-details .item .icon {
    color: var(--secound-color);
    margin-left: 10px;
    font-size: large;

}

.footer-down {
    width: 100%;
    padding: 10px 0;
    background-color: var(--main-color);

    align-items: center;
    justify-content: center;
    display: flex;
}

.footer-down .main {
    width: 70%;
    max-width: 1240px;
    align-items: center;
    justify-content: start;
    display: flex;
}

.footer-down .main.arb {
    width: 90%;
    max-width: 1340px;
    align-items: center;
    justify-content: center;
    display: flex;
}

.footer-down .main p {
    color: white;
}



/* 1024 -- 1240 */
/* 1024 -- 1240 */
/* 1024 -- 1240 */

@media (min-width: 1024px) and (max-width: 1240px) {

    .footer {
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
        display: flex;
        padding: 25px 0;
        padding-bottom: 60px;
    }

    .footer .main {
        width: 95%;
        align-items: start;
        justify-content: space-between;
        display: flex;
    }

    .footer .main .logo {
        width: 25%;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
    }

    .footer .main .logo img {
        width: 80%;
    }


    /* Quick */
    /* Quick */
    /* Quick */


    .footer .main .quick {
        width: 25%;
        align-items: center;
        justify-content: start;
        display: flex;
        flex-direction: column;

    }

    .footer .main .quick h2 {
        width: 100%;
    }



    .footer .main .quick .border {
        width: 80%;

    }

    .footer .main .quick ul {
        width: 90%;
    }

    .footer .main .quick ul li {
        margin: 0;
        margin: 5px 0;
        text-align: start;
    }

    .footer .main .quick ul li.arb {
        margin: 0;
        margin: 5px 0;
        text-align: end;
        margin-right: 45px;
    }

    .footer .main .quick ul>li>a {
        margin: 5px 0;
        font-size: 18px;
        color: black;
    }


    /* Contact */
    /* Contact */
    /* Contact */


    .footer .main .contactus {
        width: 45%;
        align-items: end;
        justify-content: start;
        display: flex;
        flex-direction: column;

    }

    .footer .main .contactus h2 {
        width: 100%;
        text-align: end;

    }

    .footer .main .contactus .border {
        width: 90%;

    }

    .footer .main .contactus .contact-details {
        margin-top: 23px;
        width: 100%;
        align-items: end;
        justify-content: start;
        display: flex;
        flex-direction: column;

    }

    .footer .main .contactus .contact-details .item {
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: row-reverse;
        margin: 2px 0;
    }

    .footer .main .contactus .contact-details .item .icon {
        color: var(--secound-color);
        margin-left: 10px;
        font-size: large;

    }

    .footer-down {
        width: 100%;
        padding: 10px 0;
        background-color: var(--main-color);

        align-items: center;
        justify-content: center;
        display: flex;
    }

    .footer-down .main {
        width: 70%;
        max-width: 1000px;
        align-items: center;
        justify-content: start;
        display: flex;
    }

    .footer-down .main.arb {
        width: 90%;
        max-width: 1340px;
        align-items: center;
        justify-content: center;
        display: flex;
    }

    .footer-down .main p {
        color: white;
    }



}

/* 768 -- 1023 */
/* 768 -- 1023 */
/* 768 -- 1023 */

@media (min-width: 768px) and (max-width: 1023px) {

    .footer {
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
        display: flex;
        padding: 25px 0;
        padding-bottom: 70px;
    }

    .footer .main {
        width: 95%;
        align-items: start;
        justify-content: space-between;
        display: flex;
    }

    .footer .main .logo {
        width: 25%;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
    }

    .footer .main .logo img {
        width: 80%;
    }


    /* Quick */
    /* Quick */
    /* Quick */


    .footer .main .quick {
        width: 25%;
        align-items: center;
        justify-content: start;
        display: flex;
        flex-direction: column;

    }

    .footer .main .quick h2 {
        width: 100%;
    }



    .footer .main .quick .border {
        width: 80%;

    }

    .footer .main .quick ul {
        width: 90%;
    }

    .footer .main .quick ul li {
        margin: 0;
        margin: 5px 0;
        text-align: start;
    }

    .footer .main .quick ul li.arb {
        margin: 0;
        margin: 5px 0;
        text-align: end;
        margin-right: 45px;
    }

    .footer .main .quick ul>li>a {
        margin: 5px 0;
        font-size: 16px;
        color: black;
    }


    /* Contact */
    /* Contact */
    /* Contact */


    .footer .main .contactus {
        width: 45%;
        align-items: end;
        justify-content: start;
        display: flex;
        flex-direction: column;

    }

    .footer .main .contactus h2 {
        width: 100%;
        text-align: end;

    }

    .footer .main .contactus .border {
        width: 90%;

    }

    .footer .main .contactus .contact-details {
        margin-top: 23px;
        width: 100%;
        align-items: end;
        justify-content: start;
        display: flex;
        flex-direction: column;

    }

    .footer .main .contactus .contact-details .item {
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: row-reverse;
        margin: 2px 0;
    }

    .footer .main .contactus .contact-details .item .icon {
        color: var(--secound-color);
        margin-left: 10px;
        font-size: large;

    }

    .footer-down {
        width: 100%;
        padding: 10px 0;
        background-color: var(--main-color);

        align-items: center;
        justify-content: center;
        display: flex;
    }

    .footer-down .main {
        width: 70%;
        align-items: center;
        justify-content: start;
        display: flex;
    }

    .footer-down .main.arb {
        width: 90%;
        max-width: 1340px;
        align-items: center;
        justify-content: center;
        display: flex;
    }

    .footer-down .main p {
        color: white;
    }



}

/* 480 -- 767 */
/* 480 -- 767 */
/* 480 -- 767 */

@media (min-width: 480px) and (max-width: 767px) {

    .footer {
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
        display: flex;
        padding: 25px 0;
        padding-bottom: 80px;
    }

    .footer .main {
        width: 95%;
        align-items: start;
        justify-content: space-between;
        display: flex;
        flex-wrap: wrap;
    }

    .footer .main .logo {
        width: 40%;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
    }

    .footer .main .logo img {
        width: 80%;
    }


    /* Quick */
    /* Quick */
    /* Quick */


    .footer .main .quick {
        width: 60%;
        align-items: center;
        justify-content: start;
        display: flex;
        flex-direction: column;

    }

    .footer .main .quick h2 {
        width: 100%;
    }



    .footer .main .quick .border {
        width: 80%;

    }

    .footer .main .quick ul {
        width: 90%;
    }

    .footer .main .quick ul li {
        margin: 0;
        margin: 5px 0;
        text-align: start;
    }

    .footer .main .quick ul li.arb {
        margin: 0;
        margin: 5px 0;
        text-align: end;
        margin-right: 60px;
    }

    .footer .main .quick ul>li>a {
        margin: 5px 0;
        font-size: 16px;
        color: black;
    }


    /* Contact */
    /* Contact */
    /* Contact */


    .footer .main .contactus {
        width: 90%;
        align-items: end;
        justify-content: start;
        display: flex;
        flex-direction: column;

    }

    .footer .main .contactus h2 {
        width: 100%;
        text-align: end;

    }

    .footer .main .contactus .border {
        width: 90%;

    }

    .footer .main .contactus .contact-details {
        margin-top: 23px;
        width: 100%;
        align-items: end;
        justify-content: start;
        display: flex;
        flex-direction: column;

    }

    .footer .main .contactus .contact-details .item {
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: row-reverse;
        margin: 2px 0;
    }

    .footer .main .contactus .contact-details .item .icon {
        color: var(--secound-color);
        margin-left: 10px;
        font-size: large;

    }

    .footer-down {
        width: 100%;
        padding: 5px 0;
        background-color: var(--main-color);

        align-items: center;
        justify-content: center;
        display: flex;
    }

    .footer-down .main {
        width: 90%;
        align-items: center;
        justify-content: start;
        display: flex;
    }

    .footer-down .main.arb {
        width: 90%;
        align-items: center;
        justify-content: center;
        display: flex;
    }

    .footer-down .main p {
        color: white;
    }



}

/* 260 -- 479 */
/* 260 -- 479 */
/* 260 -- 479 */

@media (min-width: 260px) and (max-width: 479px) {

    .footer {
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
        display: flex;
        padding: 25px 0;
        padding-bottom: 80px;
    }

    .footer .main {
        width: 95%;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-wrap: wrap;
    }

    .footer .main .logo {
        width: 90%;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        margin-bottom: 30px;
    }

    .footer .main .logo img {
        width: 60%;
    }


    /* Quick */
    /* Quick */
    /* Quick */


    .footer .main .quick {
        width: 90%;
        align-items: center;
        justify-content: start;
        display: flex;
        flex-direction: column;

    }

    .footer .main .quick h2 {
        width: 100%;
    }



    .footer .main .quick .border {
        margin-top: 5px;
        width: 80%;
        height: 2px;

    }

    .footer .main .quick ul {
        width: 90%;
    }

    .footer .main .quick ul li {
        margin: 0;
        margin: 5px 0;
        text-align: start;
    }

    .footer .main .quick ul li.arb {
        margin: 0;
        margin: 5px 0;
        text-align: end;
        margin-right: 40px;
    }

    .footer .main .quick ul>li>a {
        margin: 5px 0;
        font-size: 16px;
        color: black;
    }


    /* Contact */
    /* Contact */
    /* Contact */


    .footer .main .contactus {
        width: 90%;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;

    }

    .footer .main .contactus h2 {
        width: 90%;
        text-align: center;

    }

    .footer .main .contactus .border {
        margin-top: 5px;
        width: 80%;
        height: 2px;

    }

    .footer .main .contactus .contact-details {
        margin-top: 23px;
        width: 100%;
        align-items: center;
        justify-content: start;
        display: flex;
        flex-direction: column;

    }


    .footer .main .contactus .contact-details .item {
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: row-reverse;
        margin: 2px 0;
    }

    .footer .main .contactus .contact-details .item .icon {
        color: var(--secound-color);
        margin-left: 10px;
        font-size: large;

    }

    .footer-down {
        width: 100%;
        padding: 10px 0;
        background-color: var(--main-color);

        align-items: center;
        justify-content: center;
        display: flex;
    }

    .footer-down .main {
        width: 90%;
        max-width: 1340px;
        align-items: center;
        justify-content: start;
        display: flex;
    }

    .footer-down .main.arb {
        width: 95%;
        align-items: center;
        justify-content: center;
        display: flex;
    }

    .footer-down .main p {
        
        color: white;
    }



}