.contact {
    width: 100%;
    height: 450px;
    align-items: center;
    justify-content: center;
    display: flex;
    position: relative;
    padding: 130px 0;
}

.contact::after {
    content: "";
    position: absolute;
    width: 50%;
    height: 100%;
    top: 0;
    left: 0;
    background-image: url("../images/who/02.jpg");
    background-size: cover;
    background-position: center;
}

.contact.right::after {
    content: "";
    position: absolute;
    width: 50%;
    height: 100%;
    top: 0;
    left: auto;
    right: 0;
    background-image: url("../images/who/01.jpg");
    background-size: cover;
    background-position: center;
}

.contact.insta::after {
    background-image: url("../images/who/04.jpg");
}

.contact.ema::after {
    background-image: url("../images/who/03.jpg");

}



.contact .main {
    width: 90%;
    max-width: 1340px;
    align-items: center;
    justify-content: end;
    display: flex;

}

.contact.right .main {
    width: 90%;
    max-width: 1340px;
    align-items: center;
    justify-content: start;
    display: flex;

}

.contact .main .dis {
    width: 45%;
}

.contact .main .dis h1 {
    width: 100%;

}

.contact .main .dis .border {
    margin: 10px 0;

}

.contact .main .dis p {
    width: 100%;
    text-align: left;
}

.contact .main .dis p.arb {
    text-align: right;
}

.contact .border-bottom {
    position: absolute;
    z-index: 11;
    bottom: 0;
    width: 100%;
    background-color: var(--secound-color);
    height: 25px;
}



.contact .main .dis .contact-details {
    width: 100%;
    height: 100%;
    align-items: start;
    justify-content: center;
    display: flex;
    flex-direction: column;
    margin: 20px 0;
}

.contact .main .dis .contact-details.arb {
    align-items: end;
}

.contact .main .dis .contact-details .item {
    align-items: center;
    justify-content: start;
    display: flex;
}

.contact .main .dis .contact-details.arb .item {
    flex-direction: row-reverse;
}


.contact .main .dis .contact-details .item {
    align-items: center;
    justify-content: start;
    display: flex;
    margin: 2px 0;
}

.contact .main .dis .contact-details .item .icon {
    font-size: 18px;
    margin: 0;
    padding: 0;
    margin-right: 20px;
    color: var(--secound-color);
}

.contact .main .dis .contact-details.arb .item .icon {
    margin-right: 0px;
    margin-left: 20px;
    transform: scaleX(-1);

}

.contact .main .dis .contact-details .item .icon.email {
    font-size: 21px;

}

.contact .main .dis .contact-details .item .icon.cal {
    font-size: 22px;

}

.contact .main .dis .contact-details .item p {
    font-size: 18px;
}



/* 1024 -- 1240 */
/* 1024 -- 1240 */
/* 1024 -- 1240 */

@media (min-width: 1024px) and (max-width: 1240px) {
    .contact {
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
        display: flex;
        position: relative;
        padding: 50px 0;
    }

    .contact .main {
        width: 95%;

    }

    .contact .main .dis {
        width: 45%;
    }

    .contact .main .dis h1 {
        width: 100%;

    }

    .contact .main .dis .border {
        margin: 10px 0;

    }

    .contact .main .dis p {
        width: 100%;
        text-align: left;
    }

    .contact .main .dis p.arb {
        text-align: right;
    }

    .contact .border-bottom {
        position: absolute;
        z-index: 11;
        bottom: 0;
        width: 100%;
        background-color: var(--secound-color);
        height: 25px;
    }



    .contact .main .dis .contact-details {
        width: 100%;
        height: 100%;
        align-items: start;
        justify-content: center;
        display: flex;
        flex-direction: column;
        margin: 20px 0;
    }

    .contact .main .dis .contact-details.arb {
        align-items: end;
    }

    .contact .main .dis .contact-details .item {
        align-items: center;
        justify-content: start;
        display: flex;
    }

    .contact .main .dis .contact-details.arb .item {
        flex-direction: row-reverse;
    }


    .contact .main .dis .contact-details .item {
        align-items: center;
        justify-content: start;
        display: flex;
        margin: 2px 0;
    }

    .contact .main .dis .contact-details .item .icon {
        font-size: 18px;
        margin-right: 10px;
    }



    .contact .main .dis .contact-details .item .icon.email {
        font-size: 20px;

    }

    .contact .main .dis .contact-details .item .icon.cal {
        font-size: 20px;

    }

    .contact .main .dis .contact-details .item p {
        font-size: 16px;
    }
}


/* 768 -- 1023 */
/* 768 -- 1023 */
/* 768 -- 1023 */

@media (min-width: 768px) and (max-width: 1023px) {


    .contact {
        padding: 50px 0;
    }




    .contact .main {
        width: 95%;

    }

    .contact .main .dis {
        width: 45%;
    }

    .contact .main .dis h1 {
        width: 100%;

    }

    .contact .main .dis .border {
        margin: 10px 0;

    }

    .contact .main .dis p {
        width: 100%;
        text-align: left;
    }

    .contact .main .dis p.arb {
        text-align: right;
    }


    .contact .main .dis .contact-details {
        width: 100%;
        height: 100%;
        margin: 20px 0;
    }

    .contact .main .dis .contact-details.arb {
        align-items: end;
    }

    .contact .main .dis .contact-details .item {
        align-items: center;
        justify-content: start;
        display: flex;
    }

    .contact .main .dis .contact-details.arb .item {
        flex-direction: row-reverse;
    }


    .contact .main .dis .contact-details .item {
        align-items: center;
        justify-content: start;
        display: flex;
        margin: 2px 0;
    }

    .contact .main .dis .contact-details .item .icon {
        font-size: 16px;
        margin-right: 10px;
    }



    .contact .main .dis .contact-details .item .icon.email {
        font-size: 18px;

    }

    .contact .main .dis .contact-details .item .icon.cal {
        font-size: 18px;

    }

    .contact .main .dis .contact-details .item p {
        font-size: 14px;
    }
}

/* 480 -- 767 */
/* 480 -- 767 */
/* 480 -- 767 */

@media (min-width: 480px) and (max-width: 767px) {
    .contact {
        width: 100%;
        height: 750px;
        align-items: start;
        justify-content: center;
        display: flex;
        position: relative;
        padding: 40px 0;
    }

    .contact.pho {
        height: 640px;
    }

    .contact.ema {
        height: 660px;
    }

    .contact.insta {
        height: 660px;
    }

    .contact::after {
        width: 100%;
        height: 420px;
        top: auto;
        bottom: 0;
        left: 0;
    }

    .contact.right::after {
        width: 100%;
        height: 50%;
        top: auto;
        bottom: 0;
        left: 0;
    }


    .contact .main {
        width: 90%;
        max-width: 550px;
        align-items: center;
        justify-content: center;
        display: flex;

    }

    .contact.right .main {
        width: 90%;
        max-width: 550px;
        align-items: center;
        justify-content: center;
        display: flex;

    }


    .contact .main .dis {
        width: 100%;
    }



    .contact .main .dis .border {
        margin: 10px 0;
    }

    .contact .main .dis p {
        width: 100%;
    }

    .contact .border-bottom {
        height: 20px;
    }



    .contact .main .dis .contact-details {
        margin: 10px 0;
    }

    .contact .main .dis .contact-details .item .icon {
        font-size: 16px;
        margin-right: 20px;
    }

    .contact .main .dis .contact-details.arb .item .icon {
        margin-left: 20px;
        transform: scaleX(-1);

    }

    .contact .main .dis .contact-details .item .icon.email {
        font-size: 18px;

    }

    .contact .main .dis .contact-details .item .icon.cal {
        font-size: 18px;

    }

    .contact .main .dis .contact-details .item p {
        font-size: 15px;
    }


}


/* 275 -- 479 */
/* 275 -- 479 */
/* 275 -- 479 */

@media (min-width: 275px) and (max-width: 479px) {


    .contact {
        width: 100%;
        height: 750px;
        align-items: start;
        justify-content: center;
        display: flex;
        position: relative;
        padding: 30px 0;
    }

    .contact::after {
        width: 100%;
        height: 400px;
        top: auto;
        bottom: 0;
        left: 0;
    }

    .contact.right::after {
        width: 100%;
        height: 400px;
        top: auto;
        bottom: 0;
        left: 0;
    }


    .contact .main {
        width: 90%;
        align-items: center;
        justify-content: center;
        display: flex;


    }

    .contact.pho {
        height: 640px;
    }

    .contact.ema {
        height: 660px;
    }

    .contact.insta {
        height: 660px;
    }

    .contact.right .main {
        width: 90%;
        align-items: center;
        justify-content: center;
        display: flex;
    }


    .contact .main .dis {
        width: 100%;
    }



    .contact .main .dis .border {
        margin: 10px 0;
    }

    .contact .main .dis p {
        width: 100%;
    }

    .contact .border-bottom {
        height: 20px;
    }



    .contact .main .dis .contact-details {
        margin: 10px 0;
    }

    .contact .main .dis .contact-details .item .icon {
        font-size: 16px;
        margin-right: 20px;
    }

    .contact .main .dis .contact-details.arb .item .icon {
        margin-left: 20px;
        transform: scaleX(-1);

    }

    .contact .main .dis .contact-details .item .icon.email {
        font-size: 18px;

    }

    .contact .main .dis .contact-details .item .icon.cal {
        font-size: 18px;

    }

    .contact .main .dis .contact-details .item p {
        font-size: 15px;
    }


}