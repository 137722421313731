:root {
  --main-color: #e92b18;
  --secound-color: #5fb676;
}

.App {
  width: 100%;
  display: block;
}

::selection {
  background-color: var(--main-color);
  color: white;
}

ul {
  list-style: none;
}

a{
  text-decoration: none;
}

@media screen and (max-width: 275px) {

  .App {
    width: 100%;
    display: none;
  }

}




/* h1 */
/* h1 */
/* h1 */


h1 {
  width: 100%;
  color: var(--main-color);
  font-family: "Oswald", sans-serif;
  font-size: 50px;
  font-weight: 700;
  text-align: left;
  margin: 0;
  padding: 0;
}

h1.arb {
  font-family: "Noto Kufi Arabic", sans-serif;
  font-size: 48px;
  font-weight: 600;
  text-align: right;
}


/* h2 */
/* h2 */
/* h2 */





h2 {

  width: 100%;
  color: black;
  text-align: center;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.603);
  letter-spacing: 0;
  font-size: 32px;
  font-family: "Oswald", sans-serif;
  font-weight: 500;
  margin: 0;
  padding: 0;
}

h2.arb {
  font-size: 30px;
  font-family: "Noto Kufi Arabic", sans-serif;
  font-weight: 600;
}



p {
  width: 100%;
  margin: 0;
  padding: 0;
  font-size: 20px;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  text-align: left;
}

p.arb {
  font-size: 20px;
  font-weight: 400;
  font-family: "Noto Kufi Arabic", sans-serif;
  text-align: right;
}





/*  Border */
.border {
  width: 100%;
  height: 5px;
  border-radius: 5px;
  background-color: var(--main-color);
}

/* 1024 -- 1240 */
/* 1024 -- 1240 */
/* 1024 -- 1240 */


@media (min-width:1024px) and (max-width:1240px) {
  /* h1 */
  /* h1 */
  /* h1 */


  h1 {
    font-size: 42px;
    font-weight: 600;
  }

  h1.arb {
    font-size: 40px;
    font-weight: 600;
  }


  /* h2 */
  /* h2 */
  /* h2 */





  h2 {
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: black;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.603);
    font-size: 26px;
    font-weight: 500;
  }

  h2.arb {
    font-size: 24px;
    font-weight: 500;
  }



  p {
    font-size: 18px;
    font-weight: 400;
  }

  p.arb {
    font-size: 18px;
    font-weight: 400;
  }
}


/* 768 -- 1024  */
/* 768 -- 1024  */
/* 768 -- 1024  */


@media (min-width:768px) and (max-width:1023px) {
  /* h1 */
  /* h1 */
  /* h1 */


  h1 {
    font-size: 32px;
    font-weight: 600;
  }

  h1.arb {
    font-size: 30px;
    font-weight: 600;
  }


  /* h2 */
  /* h2 */
  /* h2 */

  h2 {
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: black;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.603);
    font-size: 22px;
    font-weight: 500;
  }

  h2.arb {
    font-size: 22px;
    font-weight: 500;
  }



  p {
    font-size: 16px;
    font-weight: 400;
  }

  p.arb {
    font-size: 16px;
    font-weight: 400;
  }
}

/* 480 -- 767  */
/* 480 -- 767  */
/* 480 -- 767  */


@media (min-width:480px) and (max-width:767px) {
  /* h1 */
  /* h1 */
  /* h1 */


  h1 {
    font-size: 32px;
    font-weight: 600;
  }

  h1.arb {
    font-size: 30px;
    font-weight: 600;
  }


  /* h2 */
  /* h2 */
  /* h2 */

  h2 {
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: black;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.603);
    font-size: 20px;
    font-weight: 500;
  }

  h2.arb {
    font-size: 20px;
    font-weight: 500;
  }



  p {
    font-size: 16px;
    font-weight: 300;
  }

  p.arb {
    font-size: 16px;
    font-weight: 300;
  }
}


@media (min-width:400px) and (max-width:479px) {
  /* h1 */
  /* h1 */
  /* h1 */


  h1 {
    font-size: 28px;
    font-weight: 600;
  }

  h1.arb {
    font-size: 26px;
    font-weight: 600;
  }


  /* h2 */
  /* h2 */
  /* h2 */

  h2 {
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: black;
    text-shadow: none;
    font-size: 16px;
    font-weight: 400;
  }

  h2.arb {
    font-size: 16px;
    font-weight: 400;
  }



  p {
    font-size: 14px;
    font-weight: 400;
  }

  p.arb {
    font-size: 14px;
    font-weight: 400;
  }
}

@media (min-width:275px) and (max-width:399px) {
  /* h1 */
  /* h1 */
  /* h1 */


  h1 {
    font-size: 25px;
    font-weight: 600;
  }

  h1.arb {
    font-size: 24px;
    font-weight: 600;
  }


  /* h2 */
  /* h2 */
  /* h2 */

  h2 {
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: black;
    text-shadow: none;
    font-size: 16px;
    font-weight: 400;
  }

  h2.arb {
    font-size: 16px;
    font-weight: 400;
  }



  p {
    font-size: 14px;
    font-weight: 400;
  }

  p.arb {
    font-size: 14px;
    font-weight: 400;
  }


}



