.who {
    height: 100%;
    align-items: start;
    justify-content: center;
    display: flex;
    width: 100%;
    position: relative;
}

/* Background */
/* Background */
/* Background */

.who .who-bg {
    height: 100%;
    align-items: start;
    justify-content: center;
    display: flex;
    width: 100%;
    position: relative;
}

/* Background img */
/* Background img */
/* Background img */
.who .who-bg img.who-gray {
    position: absolute;
    width: 900px;
    bottom: 1100px;
    left: 50%;
    transform: translateX(-50%) rotate(-20deg);
    z-index: 1;
}

.who .who-bg img.who-gray-1 {
    position: absolute;
    width: 900px;
    bottom: 2600px;
    left: 50%;
    transform: translateX(-50%) rotate(-20deg);
    z-index: 1;
}

.who .who-bg img.who-gray-2 {
    position: absolute;
    width: 900px;
    bottom: 1700px;
    left: 10%;
    transform: translateX(-50%) rotate(-20deg);
    z-index: 1;
}

.who .who-bg img.who-gray-3 {
    position: absolute;
    width: 900px;
    bottom: 100px;
    right: -45%;
    transform: translateX(-50%) rotate(-20deg);
    z-index: 1;
}

.who .who-bg img.who-gray-4 {
    position: absolute;
    width: 900px;
    bottom: 3400px;
    right: -45%;
    transform: translateX(-50%) rotate(-20deg);
    z-index: 1;
}

/* Background content */
/* Background content */
/* Background content */
.who .who-bg .main {
    width: 90%;
    max-width: 1340px;
    height: 100%;
    padding: 40px 0;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    gap: 20px;
    z-index: 30;
}

/* Who we are heading */
/* Who we are heading */
/* Who we are heading */

.who .who-bg h1 {
    text-align: center;
}



/* Who we are border */
/* Who we are border */
/* Who we are border */

.who .who-bg .main .border {
    width: 50%;
}

/* List */
.who .who-bg .main .list {
    width: 90%;
    max-width: 1340px;
    padding: 40px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px;
}


.who .who-bg .main .item {
    width: calc(40% - 30px);
    background-color: rgba(255, 255, 255, 0.577);
    box-shadow: 4px 4px 6px #0d275045,
        -3px -3px 5px #FFFFFF,
        inset -2px -2px 3px #FFFFFF,
        inset 3px 3px 6px #0d275045;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    align-items: start;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border: 3px solid var(--main-color);
    flex-grow: 1;
    box-sizing: border-box;
}

.who .who-bg .main .item .img {
    width: 100%;
    aspect-ratio: 9/7;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.who .who-bg .main .item .img img {
    width: 100%;
    transition: all 2s ease-in-out;
}

/* Hover */
/* Hover */
/* Hover */


.who .who-bg .main .item:hover .img img {
    transform: scale(1.3);
}



.who .who-bg .main .item .dis {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    flex-grow: 1;
    /* Added */
}

.who .who-bg .main .item .dis h2 {
    margin: 10px 0 0;
    /* Adjusted */
    width: 90%;
    text-align: left;
    color: var(--main-color);
    -webkit-text-stroke-width: 0px;
    text-shadow: none;
    padding: 0;
}

.who .who-bg .main .item .dis h2.arb {
    text-align: right;
}

.who .who-bg .main .item .dis p {
    width: 90%;
    margin: 0;
    padding: 0;
    margin-bottom: 15px;
}


/* 1024 -- 1240 */
/* 1024 -- 1240 */
/* 1024 -- 1240 */

@media (min-width: 1024px) and (max-width: 1240px) {
 

    .who .who-bg .main {
        width: 95%;
        min-width: 1000px;
        height: 100%;
        padding: 40px 0;
        gap: 20px;
        z-index: 20;
    }
    

    
    
    /* Who we are border */
    /* Who we are border */
    /* Who we are border */
    
    .who .who-bg .main .border {
        width: 60%;
    }
    
    /* List */
    .who .who-bg .main .list {
        width: 90%;
        min-height: 1000px;
        padding: 40px 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 30px;
    }
    
    
    .who .who-bg .main .item {
        width: calc(40% - 30px);
       
    }
    
    .who .who-bg .main .item .dis p {
        width: 90%;
        margin: 0;
        padding: 0;
        margin-bottom: 15px;
    }

}

/* 768 -- 1023 */
/* 768 -- 1023 */
/* 768 -- 1023 */

@media (min-width: 768px) and (max-width: 1240px) {
    .who .who-bg .main {
        width: 98%;
        height: 100%;
        padding: 40px 0;
        gap: 10px;
        z-index: 20;
    }
    

    
    
    /* Who we are border */
    /* Who we are border */
    /* Who we are border */
    
    .who .who-bg .main .border {
        width: 70%;
    }
    
    /* List */
    .who .who-bg .main .list {
        width: 90%;
        padding: 40px 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 30px;
    }
    
    
    .who .who-bg .main .item {
        width: calc(45% - 10px);
       
    }
    
    .who .who-bg .main .item .dis p {
    
        margin-bottom: 10px;
    }
 
    
}


/* 480 -- 767 */
/* 480 -- 767 */
/* 480 -- 767 */

@media (min-width: 480px) and (max-width: 767px) {
    .who .who-bg .main {
        width: 98%;
        height: 100%;
        padding: 40px 0;
        gap: 10px;
        z-index: 20;
    }
    

    
    
    /* Who we are border */
    /* Who we are border */
    /* Who we are border */
    
    .who .who-bg .main .border {
        width: 90%;
    }
    
    /* List */
    .who .who-bg .main .list {
        width: 90%;
        padding: 40px 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 30px;
    }
    
    
    .who .who-bg .main .item {
        width: 90%;
       
    }
    
    .who .who-bg .main .item .dis p {
    
        margin-bottom: 10px;
    }
 
    
}


/* 275 -- 479 */
/* 275 -- 479 */
/* 275 -- 479 */

@media (min-width: 275px) and (max-width: 479px) {
    .who .who-bg .main {
        width: 98%;
        height: 100%;
        padding: 40px 0;
        gap: 10px;
        z-index: 20;
    }
    

    
    
    /* Who we are border */
    /* Who we are border */
    /* Who we are border */
    
    .who .who-bg .main .border {
        width: 90%;
    }
    
    /* List */
    .who .who-bg .main .list {
        width: 90%;
        padding: 40px 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 30px;
    }
    
    
    .who .who-bg .main .item {
        width: 90%;
       
    }
    
    .who .who-bg .main .item .dis p {
    
        margin-bottom: 10px;
    }
 
    
}
