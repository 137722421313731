.mission {
    width: 100%;
    height: 100%;
    align-items: start;
    justify-content: center;
    display: flex;
    background-image: url("../images/mission/011.jpg");
    background-size: cover;
    background-position: start;
    background-repeat: no-repeat;
    overflow: hidden;
    position: relative;
    padding-top: 100px;
}

.mission::after {
    z-index: 10;
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    width: 100%;
    height: 100%;
    background-color: #ffffffe3;
}

.main{
    
}

.mission .border-top {
    position: absolute;
    z-index: 11;
    top: 0;
    width: 100%;
    background-color: var(--main-color);
    height: 25px;
}

.mission .border-bottom {
    position: absolute;
    z-index: 11;
    bottom: 0;
    width: 100%;
    background-color: var(--secound-color);
    height: 25px;
}

.mission .main {
    z-index: 11;
    align-items: center;
    width: 90%;
    height: 100%;
    align-items: start;
    justify-content: start;
    display: flex;
    flex-direction: column;
    position: relative;
    padding-bottom: 200px;
}

.mission .main h1 {
    width: 60%;
    text-align: center;
}

.mission .main .border {
    width: 60%;
}

.mission .main .dis {
    margin-top: 30px;
    align-items: center;
    justify-content: start;
    display: flex;
}

.mission .main .dis p {
    width: 100%;
    text-align: left;
}
.mission .main .dis p {
    width: 60%;
    text-align: left;
}

.mission .main .img {
    position: absolute;
    width: 400px;
    height: 400px;
    right: 0;
    bottom: 0;
}


/* 1024 -- 1240 */
/* 1024 -- 1240 */
/* 1024 -- 1240 */

@media (min-width: 1024px) and (max-width: 1240px) {
    .mission {
        padding-top: 100px;
    }
    

    
    .mission .border-top {
        height: 25px;
    }
    
    .mission .border-bottom {
        height: 25px;
    }
    
    .mission .main {
        width: 90%;
        height: 100%;
        padding-bottom: 200px;
    }
    
    .mission .main h1 {
        width: 60%;
        text-align: center;
    }
    
    .mission .main .border {
        width: 60%;
    }
    
    .mission .main .dis {
        margin-top: 20px;
        align-items: center;
        justify-content: start;
        display: flex;
    }
    
    .mission .main .dis p {
        width: 100%;
        text-align: left;
    }
    .mission .main .dis p {
        width: 60%;
        text-align: left;
    }
    
    .mission .main .img {
        width: 350px;
        height: 350px;
    }
    .mission .main .img img{
      width: 320px;
    }

}


/* 768 -- 1023 */
/* 768 -- 1023 */
/* 768 -- 1023 */

@media (min-width: 768px) and (max-width: 1023px) {
    .mission {
        padding-top: 70px;
    }
    

    
    .mission .border-top {
        height: 25px;
    }
    
    .mission .border-bottom {
        height: 25px;
    }
    
    .mission .main {
        width: 95%;
        height: 100%;
        padding-bottom: 270px;
    }
    
    .mission .main h1 {
        width: 65%;
        text-align: center;
    }
    
    .mission .main .border {
        width: 65%;
    }
    
    .mission .main .dis {
        margin-top: 20px;
        align-items: center;
        justify-content: start;
        display: flex;
    }
    
    .mission .main .dis p {
        width: 100%;
        text-align: left;
    }
    .mission .main .dis p {
        width: 60%;
        text-align: left;
    }
    
    .mission .main .img {
        width: 320px;
        height: 320px;
    }
    .mission .main .img img{
      width: 320px;
    }

}


/* 480 -- 767 */
/* 480 -- 767 */
/* 480 -- 767 */

@media (min-width: 480px) and (max-width: 767px) {
    .mission {
        padding-top: 60px;
    }
    

    
    .mission .border-top {
        height: 20px;
    }
    
    .mission .border-bottom {
        height: 20px;
    }
    
    .mission .main {
        
        width: 90%;
        max-width: 605px;
        height: 100%;
        padding-bottom: 290px;
    }
    
    .mission .main h1 {
        width: 100%;
        text-align: center;
    }
    
    .mission .main .border {
        width: 100%;
    }
    
    .mission .main .dis {
        margin-top: 20px;
        align-items: center;
        justify-content: start;
        display: flex;
    }
    
    .mission .main .dis p {
        width: 100%;
        text-align: left;
    }
    .mission .main .dis p {
        width: 100%;
        text-align: left;
    }
    
    .mission .main .img {
        width: 290px;
        height: 290px;
        right: 00px;
    }
    .mission .main .img img{
      width: 290px;
    }

}


/* 275 -- 479 */
/* 275 -- 479 */
/* 275 -- 479 */

@media (min-width: 275px) and (max-width: 479px) {
    .mission {
        padding-top: 60px;
    }
    

    
    .mission .border-top {
        height: 20px;
    }
    
    .mission .border-bottom {
        height: 20px;
    }
    
    .mission .main {
        
        width: 90%;
        max-width: 605px;
        height: 100%;
        padding-bottom: 290px;
    }
    
    .mission .main h1 {
        width: 100%;
        text-align: center;
    }
    
    .mission .main .border {
        width: 100%;
    }
    
    .mission .main .dis {
        margin-top: 20px;
        align-items: center;
        justify-content: start;
        display: flex;
    }
    
    .mission .main .dis p {
        width: 100%;
        text-align: left;
    }
    .mission .main .dis p {
        width: 100%;
        text-align: left;
    }
    
    .mission .main .img {
        width: 290px;
        height: 290px;
        right: 00px;
    }
    .mission .main .img img{
      width: 290px;
    }

}