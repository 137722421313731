.intro {
    width: 100%;
    height: 520px;
    align-items: center;
    justify-content: center;
    display: flex;
    position: relative;
}

.intro::after {
    content: "";
    position: absolute;
    width: 50%;
    height: 100%;
    top: 0;
    right: 0;
    background-image: url("../images/intro/01.jpg");
    background-size: cover;
    background-position: center;
}

.intro .main {
    width: 90%;
    max-width: 1340px;
    align-items: center;
    justify-content: start;
    display: flex;
}

.intro .main p {
    width: 45%;
    text-align: left;
}

.intro .main p.arb {
    text-align: right;
}

.intro .border-bottom {
    position: absolute;
    z-index: 11;
    bottom: 0;
    width: 100%;
    background-color: var(--secound-color);
    height: 25px;
}


/* 1024 -- 1240 */
/* 1024 -- 1240 */
/* 1024 -- 1240 */

@media (min-width: 1024px) and (max-width: 1240px) {
    .intro {
        width: 100%;
        height: 450px;
    }

    .intro::after {
        content: "";
        position: absolute;
        width: 50%;
        height: 100%;
        top: 0;
        right: 0;
        background-image: url("../images/intro/01.jpg");
        background-size: cover;
        background-position: center;
    }

    .intro .main {
        width: 95%;
    }

}


/* 768 -- 1023 */
/* 768 -- 1023 */
/* 768 -- 1023 */

@media (min-width: 768px) and (max-width: 1240px) {
    .intro {
        width: 100%;
        height: 490px;
        align-items: center;
        justify-content: center;
        display: flex;
        position: relative;
    }


    .intro .main {
        width: 95%;
    }

    .intro .main p {
        width: 47%;
    }


}

/* 480 -- 767 */
/* 480 -- 767 */
/* 480 -- 767 */

@media (min-width: 480px) and (max-width: 767px) {
    .intro {
        width: 100%;
        height: 700px;
        align-items: start;
 
    }

    .intro::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 60%;
        top: auto;
        bottom: 0;
        right: 0;
    }

    .intro .main {
        width: 90%;
        max-width: 500px;
        height: 40%;
        align-items: center;
        justify-content: center;
        display: flex;
    }

    .intro .main p {
        width: 100%;
    }

    .intro .border-bottom {
        height: 20px;
    }




}

/* 275 -- 480 */
/* 275 -- 480 */
/* 275 -- 480 */

@media (min-width: 275px) and (max-width: 479px) {
    .intro {
        margin-top: -50px;
        width: 100%;
        height: 650px;
        align-items: start;
        justify-content: center;
      
    }

    .intro::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 60%;
        top: auto;
        bottom: 0;
        right: 0;
    }

    .intro .main {
        width: 90%;
        max-width: 500px;
        height: 40%;
        align-items: start;
        justify-content: center;
        display: flex;
    }

    .intro .main p {
        width: 100%;
    }

    .intro .border-bottom {
        height: 20px;
    }




}