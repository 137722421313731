.hero {
    width: 100%;
    height: 100%;
    align-items: start;
    justify-content: center;
    display: flex;
    position: relative;
    overflow: hidden;
    margin-top: -90px;
}

.hero .heropart {
    width: 100%;
    height: 100%;
    align-items: start;
    justify-content: center;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;

}

.hero .heropart img.gray {
    position: absolute;
    width: 900px;
    bottom: -100px;
    left: 60%;
    transform: translateX(-50%) rotate(-20deg);

}

.hero .heropart .hero-bg {
    width: 100%;
    height: 100vh;
    align-items: start;
    justify-content: center;
    display: flex;
    position: relative;
    overflow: hidden;

}

.hero .heropart .hero-bg img.red {
    position: absolute;
    width: 1100px;
    transform: rotate(-20deg);
    bottom: 00px;
    left: -500px;
    z-index: -1;

}

.hero .heropart .hero-bg img.background {
    position: absolute;
    height: 90vh;
    bottom: 00px;
    left: 30%;
    transform: translateX(-50%);
    z-index: 2;
    opacity: 0;
}

.hero .heropart .hero-bg .hero-bg-img .background {
    position: absolute;
    height: 90vh;
    bottom: 00px;
    left: 30%;
    transform: translateX(-50%);
    z-index: 2;
    opacity: 0;
}

.hero .heropart .hero-bg .hero-bg-img .showings {

    opacity: 1;
    transition: all 1s ease-in-out;
}

.hero .heropart .hero-bg .bottom-border {
    position: absolute;
    width: 100%;
    height: 50px;
    background-color: var(--main-color);
    bottom: 0;
    z-index: 3;
}


.hero .heropart .content {
    width: 90%;
    max-width: 1280px;
    height: 100%;
    z-index: 10;
    align-items: center;
    justify-content: end;
    display: flex;
    position: relative;
}

.hero .heropart .content .main {
    width: 50%;
    height: 70%;
    z-index: 10;
    position: relative;
    align-items: center;
    justify-content: center;
    display: flex;
    gap: 30px;
    flex-direction: column;

}

.hero .heropart .content .main h1.eng-sub {
    width: 100%;
    color: var(--secound-color);
    font-family: "Oswald", sans-serif;
    font-weight: 700;
    text-align: left;
    margin: 0;
    padding: 0;
    font-family: "Oswald", sans-serif;
    font-size: 43px;
    font-weight: 700;
    text-align: left;
}

.hero .heropart .content .main h1.arb-sub {
    font-family: "Noto Kufi Arabic", sans-serif;
    font-size: 40px;
    font-weight: 600;
    text-align: right;
    color: var(--secound-color);

}

.hero .heropart .content .main h2 {
    text-align: left;

}

.hero .heropart .content .main h2.arb {
    text-align: right;
}

/* 1024 -- 1240 */
/* 1024 -- 1240 */
/* 1024 -- 1240 */

@media (min-width: 1024px) and (max-width: 1240px) {
    .hero {
        margin-top: -70px;
    }

    .hero .heropart img.gray {
        width: 800px;
        bottom: -50px;
        left: 60%;

    }

    .hero .heropart .hero-bg {
        height: 90vh;
        max-height: 800px;

    }

    .hero .heropart .hero-bg img.red {
        position: absolute;
        width: 700px;
        bottom: 50px;
        left: -400px;

    }

    .hero .heropart .hero-bg img.background {
        height: 90%;
        max-height: 500px;
        left: 25%;
    }

    .hero .heropart .hero-bg .hero-bg-img .background {
        height: 90%;
        max-height: 500px;
        left: 25%;
    }


    .hero .heropart .hero-bg .bottom-border {
        height: 40px;
        bottom: 0;
    }

    .hero .heropart .content {
        width: 95%;
        max-width: 1;
    }


    .hero .heropart .content .main {
        width: 55%;
        height: 70%;
        gap: 10px;

    }

    .hero .heropart .content .main h1.eng-sub {
        font-weight: 700;
        font-size: 36px;
        text-align: left;
    }

    .hero .heropart .content .main h1.arb-sub {
        font-size: 32px;
        font-weight: 600;

    }

    .hero .heropart .content .main h2 {
        text-align: left;

    }

    .hero .heropart .content .main h2.arb {
        text-align: right;
    }
}

/* 768 -- 1023 */
/* 768 -- 1023 */
/* 768 -- 1023 */

@media (min-width: 768px) and (max-width: 1023px) {
    .hero {
        margin-top: -100px;
    }

    .hero .heropart {
        width: 100%;
        height: 100%;
    }

    .hero .heropart .hero-bg {
        width: 100%;
        height: 750px;

    }

    .hero .heropart img.gray {
        width: 600px;
        bottom: 500px;
        left: 100%;

    }

    .hero .heropart .hero-bg img.red {
        width: 600px;
        bottom: -50px;
        left: -100px;

    }

    .hero .heropart .hero-bg img.background {
        position: absolute;
        height: 100vh;
        max-height: 430px;
        bottom: 00px;
        left: 10%;
        transform: translateX(-50%);
        z-index: 2;
        opacity: 0;
    }

    .hero .heropart .hero-bg .hero-bg-img .background {
        position: absolute;
        height: 100vh;
        max-height: 430px;
        bottom: 00px;
        left: 40%;
        transform: translateX(-50%);
        z-index: 2;
        opacity: 0;
    }

    .hero .heropart .hero-bg .hero-bg-img .showings {
        opacity: 1;
        transition: all 1s ease-in-out;
    }

    .hero .heropart .hero-bg .bottom-border {
        position: absolute;
        width: 100%;
        height: 50px;
        background-color: var(--main-color);
        bottom: 0;
        z-index: 3;
    }


    .hero .heropart .content {
        width: 95%;
        height: 100%;
        z-index: 10;
        align-items: start;
        justify-content: center;
        display: flex;
        position: relative;
    }

    .hero .heropart .content .main {
        width: 80%;
        height: 60%;
        z-index: 10;
        gap: 0px;

    }

    .hero .heropart .content .main h1.eng-sub {
        width: 100%;
        font-size: 30px;
        font-weight: 500;
        text-align: left;
    }

    .hero .heropart .content .main h1.arb-sub {
        font-size: 32px;
        font-weight: 500;

    }


}


/* 768 -- 900 */
/* 768 -- 900 */
/* 768 -- 900 */



@media (max-width: 767px) and (min-width: 480px) {
    .hero {
        margin-top: -50px;
    }

    .hero .heropart {
        width: 100%;
        height: 100%;
    }

    .hero .heropart .hero-bg {
        width: 100%;
        height: 750px;

    }

    .hero .heropart img.gray {
        width: 600px;
        bottom: 500px;
        left: 100%;

    }

    .hero .heropart .hero-bg img.red {
        width: 600px;
        bottom: -50px;
        left: -100px;

    }

    .hero .heropart .hero-bg img.background {
        position: absolute;
        height: 80vw;
        max-height: 420px;
        bottom: 00px;
        left: 10%;
        transform: translateX(-50%);
        z-index: 2;
        opacity: 0;
    }

    .hero .heropart .hero-bg .hero-bg-img .background {
        position: absolute;
        height: 80vw;
        max-height: 420px;
        bottom: 00px;
        left: 40%;
        transform: translateX(-50%);
        z-index: 2;
        opacity: 0;
    }

    .hero .heropart .hero-bg .hero-bg-img .showings {
        opacity: 1;
        transition: all 1s ease-in-out;
    }

    .hero .heropart .hero-bg .bottom-border {
        position: absolute;
        width: 100%;
        height: 50px;
        background-color: var(--main-color);
        bottom: 0;
        z-index: 3;
    }


    .hero .heropart .content {
        width: 95%;
        height: 750px;
        z-index: 10;
        align-items: start;
        justify-content: center;
        display: flex;
        position: relative;
    }

    .hero .heropart .content .main {
        width: 90%;
        height: 60%;
        z-index: 10;
        gap: 0px;

    }

    .hero .heropart .content .main h1.eng-sub {
        width: 100%;
        font-size: 26px;
        font-weight: 400;
        text-align: left;
    }

    .hero .heropart .content .main h1.arb-sub {
        font-size: 28px;
        font-weight: 400;

    }


}


/* 400 -- 480 */
/* 400 -- 480 */
/* 400 -- 480 */



@media (max-width: 480px) and (min-width: 400px) {
    .hero {
        margin-top: -20px;
        height: 100vh;
        overflow: hidden;
    }

    .hero .heropart {
        width: 100%;
        height: 100%;
    }

    .hero .heropart .hero-bg {
        width: 100%;
        height: 75vh;

    }

    .hero .heropart img.gray {
        width: 500px;
        bottom: 60vh;
        left: 100%;

    }

    .hero .heropart .hero-bg img.red {
        width: 600px;
        bottom: 50px;
        left: -200px;

    }

    .hero .heropart .hero-bg img.background {
        position: absolute;
        height: 90vw;
        bottom: 25px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 2;
        opacity: 0;
    }

    .hero .heropart .hero-bg .hero-bg-img .background {
        position: absolute;
        height: 90vw;
        bottom: 25px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 2;
        opacity: 0;
    }

    .hero .heropart .hero-bg .hero-bg-img .showings {
        opacity: 1;
        transition: all 1s ease-in-out;
    }

    .hero .heropart .hero-bg .bottom-border {
        position: absolute;
        width: 100%;
        height: 40px;
        background-color: var(--main-color);
        bottom: 0;
        z-index: 3;
    }


    .hero .heropart .content {
        width: 98%;
        height: 20vh;
        z-index: 10;
        align-items: start;
        justify-content: center;
        display: flex;
        position: relative;
    }

    .hero .heropart .content .main {
        width: 90%;
        height: 100%;
        z-index: 10;
        position: relative;
        align-items: center;
        justify-content: center;
        display: flex;
        gap: 0px;
        flex-direction: column;

    }

    .hero .heropart .content .main h1.eng-sub {
        width: 100%;
        font-size: 22px;
        font-weight: 400;
        text-align: left;
    }

    .hero .heropart .content .main h1.arb-sub {
        font-size: 22px;
        font-weight: 400;

    }


}


/* 275 -- 399 */
/* 275 -- 399 */
/*  275 -- 399  */



@media (max-width: 399px) and (min-width: 275px) {
    .hero {
        margin-top: 0px;
        height: 100vh;
    }

    .hero .heropart {
        width: 100%;
        height: 100%;
    }

    .hero .heropart .hero-bg {
        width: 100%;
        height: 75vh;

    }

    .hero .heropart img.gray {
        width: 300px;
        bottom: 60vh;
        left: 100%;

    }

    .hero .heropart .hero-bg img.red {
        width: 300px;
        bottom: 50px;
        left: -100px;

    }

    .hero .heropart .hero-bg img.background {
        position: absolute;
        height: 85vw;
        bottom: 25px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 2;
        opacity: 0;
    }

    .hero .heropart .hero-bg .hero-bg-img .background {
        position: absolute;
        height: 85vw;
        bottom: 25px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 2;
        opacity: 0;
    }

    .hero .heropart .hero-bg .hero-bg-img .showings {
        opacity: 1;
        transition: all 1s ease-in-out;
    }

    .hero .heropart .hero-bg .bottom-border {
        position: absolute;
        width: 100%;
        height: 40px;
        background-color: var(--main-color);
        bottom: 0;
        z-index: 3;
    }


    .hero .heropart .content {
        width: 98%;
        height: 20vh;
        z-index: 10;
        align-items: start;
        justify-content: center;
        display: flex;
        position: relative;
    }

    .hero .heropart .content .main {
        width: 90%;
        height: 100%;
        z-index: 10;
        position: relative;
        align-items: center;
        justify-content: center;
        display: flex;
        gap: 0px;
        flex-direction: column;

    }

    .hero .heropart .content .main h1.eng-sub {
        width: 100%;
        font-size: 22px;
        font-weight: 400;
        text-align: left;
    }

    .hero .heropart .content .main h1.arb-sub {
        font-size: 22px;
        font-weight: 400;

    }


}



/* Qulity */
/* Qulity */
/* Qulity */


/* .hero .qulity {
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    padding: 40px 0px;
    padding-top: 80px;
}

.hero .qulity .list {
    width: 90%;
    height: 100%;
    max-width: 1240px;
    align-items: center;
    justify-content: space-between;
    display: flex;
    z-index: 15;
}

.hero .qulity .list .item {
    width: 320px;
    height: 320px;
    border-radius: 15px;
    background-color: rgba(255, 255, 255, 0.577);
    box-shadow: 4px 4px 6px #0d275045,
        -3px -3px 5px #FFFFFF,
        inset -2px -2px 3px #FFFFFF,
        inset 3px 3px 6px #0d275045;

    align-items: center;
    justify-content: end;
    display: flex;
    flex-direction: column;
    position: relative;
}

.hero .qulity .list .item .logo {
    position: absolute;
    top: 0;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    background-color: white;
    box-shadow: 4px 4px 6px #0d275045,
        -3px -3px 5px #FFFFFF,
        inset -2px -2px 3px #FFFFFF,
        inset 3px 3px 6px #0d275045;
    transform: translate(-25%, -25%);
    align-items: center;
    justify-content: center;
    display: flex;
    transition: all 1s ease-in-out;
}

.hero .qulity .list .item:nth-child(1) .logo {
    left: 0;
    top: 0;
    transform: translate(-25%, -25%);
}

.hero .qulity .list .item:nth-child(2) .logo {
    left: 50%;
    transform: translate(-50%, -25%);
}

.hero .qulity .list .item:nth-child(3) .logo {
    right: 0;
    transform: translate(25%, -25%);
}

.hero .qulity .list .item .logo .icon {
    height: 50px;
    color: var(--main-color);
    transition: all 0.5s ease-in-out;
}

.hero .qulity .list .item .heading {
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    text-align: center;
}

.hero .qulity .list .item .heading h2 {

    text-align: center;
    color: var(--main-color);
    margin: 0;
    padding: 0;
    margin-bottom: 10px;
}

.hero .qulity .list .item .heading h2.arb {
    font-size: 30px;
    font-family: "Noto Kufi Arabic", sans-serif;
    font-weight: 600;
}

.hero .qulity .list .item .heading h2.eng {
    font-size: 34px;
    font-family: "Oswald", sans-serif;
    font-weight: 500;
}


.hero .qulity .list .item .dis {
    width: 90%;
    height: 140px;
    align-items: start;
    justify-content: center;
    display: flex;
    text-align: center;
}

.hero .qulity .list .item .dis p {
    text-align: center;
    margin: 0;
    padding: 0;
}
.hero .qulity .list .item .dis p.arb {
    font-size: 20px;
    font-weight: 600;
    font-family: "Noto Kufi Arabic", sans-serif;
}
.hero .qulity .list .item .dis p.eng {
    font-size: 20px;
    font-weight: 500;
    font-family: "Poppins",sans-serif;
}

.hero .qulity .list .item:hover .logo {
    background-color: var(--main-color);

}

.hero .qulity .list .item:hover .logo .icon {
    color: #ffffff;

} */